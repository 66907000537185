
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { CountriesInterface, Details, laborData } from '@/utils/generalInterface'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { fixDate, isEmpty } from '@/utils/general'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { ConsultRutInterface } from '@/store/persons/state'
  import { PersonFormatted } from '@/store/persons/person'
  import { EnterpriseFormatted } from '@/store/persons/enterprise'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { RULES } from '@/components/forms'
  import { FileParameter } from '@/entities/files'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { LaborData } from '@/entities/persons'
  import { Debounce } from '@/utils/decorators'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { GForm } from '@/components/forms/GForm'
  import GPhone from '@/components/core/input/GPhone.vue'
  import GRut from '@/components/core/input/GRut.vue'

@Component({
  components: { GRut, GPhone, GCostField, GFiles, GAlert, GDatePicker },
  methods: {
    ...mapActions('persons', ['createPerson', 'getPersonType', 'getCountries', 'getFilterCountries']),
    ...mapActions('persons/labor', ['findNaturalPerson', 'findEnterprise', 'sendLaborDependent', 'sendLaborEnterprise', 'cleanInfoData']),
    ...mapMutations('persons/labor', ['setRutErrorMessage']),
  },
  computed: {
    ...mapGetters('persons', [
      'countries', 'laborId',
    ]),
    ...mapGetters('persons/labor', [
      'getRutInfoLabor',
      'getLaborPerson',
      'enterprisePerson',
      'laborDataCreated',
      'rutErrorMessage',
    ]),
  },
})
  export default class LaborDataDependent extends GForm {
  @Prop({ type: LaborData, default: () => null }) info!: LaborData;
  @Prop({ type: Array, required: true }) files!: FileParameter[];
  @Prop({ type: Number, default: null }) idProcess!: number;
  @Prop({ type: Array, default: () => [] }) personType!: Details[];
  @Prop({ type: Array, default: () => [] }) rent!: Details[];
  @Prop({ type: Array, default: () => [] }) contract!: Details[];
  @Prop({
    type: Object,
    default: () => {
    },
  }) value!: any;

  $refs!: {
    form2: HTMLFormElement
  };

  fields = {
    afpCard: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        icon: '',
      },
    },
    latestSettlements: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        icon: '',
      },
    },
  }

  getPersonType!: () => void;
  valid = false
  disabledField = false
  clean = false
  blockFields = false
  setType = ''
  open = false
  title = '';
  subtitle = '';
  searchCountry = '';
  rutErrorMessage!: string;
  enterprisePerson!: EnterpriseFormatted;
  findPhoneFlag = false
  phoneLaborError = false
  findRutFlag = false
  findEnterprise!: (rut: string) => void;
  findNaturalPerson!: ({ phone, rut, id }: {
    phone: string | null;
    rut: string | null | undefined;
    id: number | null
  }) => void;

  createPerson!: ({ person, labor }: {
    person: laborData;
    labor: boolean;
  }) => void;

  cleanInfoData!: () => void;
  setRutErrorMessage!: (value: string) => void;
  getCountries!: () => void;
  getFilterCountries!: (name: string) => void;
  laborId!: number;
  laborDataCreated!: any;
  getRutInfoLabor!: ConsultRutInterface;
  getLaborPerson!: PersonFormatted | null;
  countries!: CountriesInterface[];
  fieldRequired = RULES.isRequired
  phoneRequired = RULES.isPhone
  rutRules = RULES.isRut
  siiInfo = false
  existPersonPhone = false
  loading = false

  @Prop({ type: Boolean, default: false }) send!: boolean;
  @Prop({ type: Number, default: null }) idPerson!: number;
  @Prop({ type: String, default: 'null' }) activity!: string;
  data: laborData = {
    id: -1,
    position: '',
    rentType: '',
    rent: '',
    rut: '',
    contractType: '',
    dateEmployment: '',
    birthDate: '',
    type: '',
    alias: '',
    companyName: '',
    comments: '',
    afpCard: [],
    latestSettlements: [],
  };

  sendLaborDependent!:
    ({ data, idPerson }: { data: laborData, idPerson: number }) => Promise<void>;

  cleanLaborInfo (): void {
    this.cleanInfoData()
    this.data = {
      position: '',
      rentType: '',
      rent: '',
      rut: '',
      id: -1,
      contractType: '',
      dateEmployment: '',
      type: '',
      alias: '',
      companyName: '',
      idEmployer: -1,
      comments: '',
      name: '',
      secondName: '',
      surname: '',
      secondSurname: '',
      nationality: '',
      birthDate: '',
    }
  }

  cleanEmployerNatural (): void {
    const afp = this.data.afpCard
    const settlement = this.data.latestSettlements
    this.data = {
      contractType: this.data.contractType,
      dateEmployment: this.data.dateEmployment,
      position: this.data.position,
      rentType: this.data.rentType,
      rent: this.data.rent,
      type: this.data.type,
      idEmployer: -1,
      rut: '',
      name: '',
      secondName: '',
      surname: '',
      secondSurname: '',
      nationality: '',
      birthDate: '',
      afpCard: afp,
      latestSettlements: settlement,
    }
  }

  cleanEmployerEnterprise (): void {
    const afp = this.data.afpCard
    const settlement = this.data.latestSettlements
    this.data = {
      contractType: this.data.contractType,
      dateEmployment: this.data.dateEmployment,
      position: this.data.position,
      rentType: this.data.rentType,
      rent: this.data.rent,
      type: this.data.type,
      idEmployer: -1,
      rut: '',
      alias: '',
      companyName: '',
      comments: '',
      afpCard: afp,
      latestSettlements: settlement,
    }
  }

  setLaborInfo (): void {
    if (this.setType === 'natural_person' && this.getLaborPerson?.id) {
      this.data.name = this.getLaborPerson?.name
      this.data.secondName = this.getLaborPerson?.secondName
      this.data.surname = this.getLaborPerson?.surname
      this.data.secondSurname = this.getLaborPerson?.secondSurname
      this.data.nationality = this.getLaborPerson?.nationality?.toString()
      this.data.birthDate = this.getLaborPerson?.birthDate
      this.data.phone = this.getLaborPerson?.phone
      this.data.rut = this.getLaborPerson?.rut
      this.data.idEmployer = this.getLaborPerson?.id || -1
      this.existPersonPhone = Boolean(this.getLaborPerson?.phone?.length)
    } else if (this.setType === 'company' && this.enterprisePerson?.id) {
      this.data.idEmployer = this.enterprisePerson?.id || -1
      this.data.rut = this.enterprisePerson?.rut
      this.data.alias = this.enterprisePerson?.alias
      this.data.companyName = this.enterprisePerson?.companyName
    } else {
      if (!isEmpty(this.getRutInfoLabor?.otherNames) && this.data.type === 'company') {
        this.data.rut = this.getRutInfoLabor.rut
        this.data.companyName = this.getRutInfoLabor?.otherNames || ''
      } else {
        this.setRutInfoSiiLabor(this.getRutInfoLabor)
      }
    }

    this.title = ''
    this.subtitle = ''
  }

  setRutInfoSiiLabor (info) {
    this.data.rut = info?.rut
    this.data.name = info?.firstName || ''
    this.data.secondName = info?.secondName || ''
    this.data.surname = info?.firstLastName || ''
    this.data.secondSurname = info?.secondLastName || ''

    this.siiInfo = true
  }

  findPhone (phone: string): void {
    if (!isEmpty(phone) && phone.length > 5) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          // @ts-ignore
          detail: {
            open: true,
          },
        })
      )
      this.findPhoneFlag = true
      this.findNaturalPerson({ phone, rut: null, id: this.idPerson })
    }
  }

  validateRut (rut: string, natural = true): void {
    this.setRutErrorMessage('')
    if (rut && rut.length > 7 && natural) {
      this.findRutFlag = true
      this.findNaturalPerson({ phone: null, rut: rut.toUpperCase(), id: this.idPerson })
    } else if (rut && rut.length > 7 && !natural) {
      this.findEnterprise(rut.toUpperCase())
    } else {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          // @ts-ignore
          detail: {
            message: 'Rut debe contener más de 7 caracteres',
            type: 'error',
          },
        })
      )
    }
  }

  cleanLabor (val: string): void {
    this.blockFields = false
    if (val === 'natural_person') {
      this.cleanEmployerNatural()
    } else {
      this.cleanEmployerEnterprise()
    }
    this.clean = !this.clean
  }

  confirmSetData (): void {
    this.open = false
    this.setLaborInfo()
  }

  closeAlert (): void {
    this.blockFields = false
    this.open = false
    if (this.data.type === 'natural_person') {
      this.cleanEmployerNatural()
    } else {
      this.cleanEmployerEnterprise()
    }
  }

  @Watch('getLaborPerson', { immediate: true, deep: true }) onFoundPerson (val: PersonFormatted | null): void {
    if (val) {
      this.blockFields = true
      this.setType = 'natural_person'
      this.open = true
      if (!isEmpty(this.data.phone) && this.findPhoneFlag) {
        this.findPhoneFlag = false
        this.title = `Según nuestro sistema, el telefono ${val.phone} corresponde a:`
      } else if (!isEmpty(this.data.rut) && this.findRutFlag) {
        this.findRutFlag = false
        this.title = `Según nuestro sistema, el rut ${val.rut} corresponde a:`
      }
      this.subtitle = `${val.name} ${val.secondName} ${val.surname} ${val.secondSurname} `
    } else {
      this.data.rut = null
    }
  }

  @Watch('enterprisePerson', { immediate: true, deep: true }) onEnterpriseFound (val: EnterpriseFormatted): void {
    if (val) {
      this.blockFields = true
      this.setType = 'company'
      this.open = true
      this.title = `Según nuestro sistema, el rut ${val.rut} corresponde a:`
      this.subtitle = `${val.companyName}`
    }
  }

  @Watch('getRutInfoLabor') onChangeRut (val: ConsultRutInterface): void {
    if (val) {
      if (!isEmpty(val.otherNames) && this.data.type === 'company') {
        this.setType = 'rutInfo'
        this.open = true
        this.title = `Según nuestro sistema, el rut ${val.rut} corresponde a:`
        this.subtitle = `${val.otherNames} `
      } else {
        if (!isEmpty(val.firstName) && !isEmpty(val.firstLastName)) {
          this.setType = 'natural_person'
          this.open = true
          this.title = `Según nuestro sistema, el rut ${val.rut} corresponde a:`
          this.subtitle = `${val.firstName} ${val.secondName ? val.secondName : ''} ${val.firstLastName ? val.firstLastName : ''} ${val.secondLastName ? val.secondLastName : ''} `
        }
      }
      this.data.rut = val.rut
    }
  }

  @Watch('send')
  async onSend (val): Promise<void> {
    if (val) {
      if (!this.$refs.form2.validate() || this.rutErrorMessage?.length || this.phoneLaborError) {
        await this.setFieldError()
        this.$emit('send')
        return
      }
      if (this.info?.id) {
        await this.updateLaborData(this.info.id)
        return
      }

      this.data.dateEmployment = fixDate(this.data.dateEmployment)
      if (this.data.idEmployer !== -1 && !isEmpty(this.data.idEmployer) && isEmpty(this.rutErrorMessage)) {
        const resp = await this.sendLaborDependent({ data: this.data, idPerson: this.idPerson })
        if (this.data?.latestSettlements?.length) {
          await this.handleFileType(this.data.latestSettlements, { properties: this.fields.latestSettlements.properties }, this.idProcess, resp.id)
        }
        if (this.data?.afpCard?.length) {
          await this.handleFileType(this.data.afpCard, { properties: this.fields.afpCard.properties }, this.idProcess, resp.id)
        }
        this.cleanLaborInfo()
        this.$emit('close')
      } else {
        this.createPerson({ person: this.data, labor: true })
      }
    }
  }

  async updateLaborData (id) {
    const { data, fields, idProcess } = this
    if (data?.latestSettlements?.length) {
      await this.handleFileType(data.latestSettlements, { properties: fields.latestSettlements.properties }, idProcess, id)
    }
    if (data?.afpCard?.length) {
      await this.handleFileType(data.afpCard, { properties: fields.afpCard.properties }, idProcess, id)
    }
    await this.close()
  }

  @Watch('laborId', { immediate: true })
  async onLaborIdChange (val: number): Promise<void> {
    if (val !== -1) {
      this.data.idEmployer = val
      const resp = await this.sendLaborDependent({ data: this.data, idPerson: this.idPerson })

      if (this.data?.latestSettlements?.length) {
        await this.handleFileType(this.data.latestSettlements, { properties: this.fields.latestSettlements.properties }, this.idProcess, resp.id)
      }
      if (this.data?.afpCard?.length) {
        await this.handleFileType(this.data.afpCard, { properties: this.fields.afpCard.properties }, this.idProcess, resp.id)
      }
      this.cleanLaborInfo()
      this.$emit('close')
    }
  }

  @Watch('data.type', { immediate: true, deep: true })
  onTypeChange () {
    if (this.info?.id) {
      return
    }

    this.data.rut = ''
    this.cleanInfoData()
    this.$refs.form2?.resetValidation()
  }

  @Watch('searchCountry', { immediate: true })
  onSearchCountry (val: string) {
    if (!isEmpty(val)) {
      this.getFilterCountries(val)
    } else {
      this.getCountries()
    }
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)

    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  async mounted () {
    this.setRutErrorMessage(null)
    const { personType } = this
    if (!personType?.length) {
      this.getPersonType()
    }
    const { value } = this
    if (value) {
      if (value.employer.personType.name === 'natural_person') {
        this.data = {
          contractType: value.contractType.name,
          dateEmployment: value.admissionDate,
          position: value.jobTitle,
          rentType: value.rentType.name,
          rent: value.rent,
          id: value.id,
          rut: value.employer.uid,
          idEmployer: value.employer.id,
          phone: value.employer.phone,
          type: value.employer.personType.name,
          name: value?.employer?.firstName || '',
          secondName: value?.employer?.secondName || '',
          surname: value?.employer?.surname || '',
          secondSurname: value?.employer?.secondSurname || '',
          comments: value.executiveComment,
        }
      } else {
        this.data = {
          id: value.id,
          contractType: value.contractType.name,
          dateEmployment: value.admissionDate,
          position: value.jobTitle,
          rentType: value.rentType.name,
          rent: value.rent,
          rut: value.employer.uid,
          alias: value.employer.alias,
          idEmployer: value.employer.id,
          type: value.employer.personType.name,
          comments: value.executiveComment,
          companyName: value.employer.companyName,
        }
      }
      this.disabledField = true
    } else {
      this.data = {
        id: -1,
        position: '',
        rentType: '',
        rent: '',
        rut: '',
        contractType: '',
        dateEmployment: '',
        birthDate: '',
        type: '',
        alias: '',
        companyName: '',
        afpCard: [],
        latestSettlements: [],
        comments: '',
      }
    }
  }

  @Watch('info', { immediate: true, deep: true })
  @Debounce(1000)
  async onInfoChange (val: LaborData): Promise<void> {
    if (!val) return
    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: val.id } }, { parameter: { process: { id: { _eq: this.idProcess } } } }] },
      force: true,
    })

    const afp = files?.filter(file => file.parameter.name === 'afp_card')
    const settlement = files?.filter(file => file.parameter.name === 'latest_settlements')

    this.data = {
      id: val.id,
      position: val.jobTitle,
      rentType: val.rentType.name,
      rent: val.rent?.toString(),
      rut: val.employer.uid,
      idEmployer: val.employer.id,
      dateEmployment: val.admissionDate?.format('YYYY-MM-DD'),
      contractType: val.contractType.name,
      phone: val.employer.mainPhone,
      birthDate: val.employer.birthDate?.format('YYYY-MM-DD'),
      nationality: val.employer.country?.name,
      type: val.employer.type.name,
      alias: val.employer.alias,
      name: val.employer.firstName,
      secondName: val.employer.secondName,
      surname: val.employer.surname,
      secondSurname: val.employer.secondSurname,
      companyName: val.employer.companyName,
      afpCard: afp,
      latestSettlements: settlement,
      comments: '',
    }
  }

  phoneLabor (flag: boolean) {
    this.phoneLaborError = flag
  }

  @Watch('files', { immediate: true, deep: true })
  onFilesChange (val: FileParameter[]): void {
    this.setProperties(val, 'latest_settlements', 'latestSettlements')
    this.setProperties(val, 'afp_card', 'afpCard')
  }

  get disabledLaborData () {
    const { info } = this

    return Boolean(info?.id)
  }
  }
